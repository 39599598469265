<template>
  <div class="sctp-card">
    <div class="sctp-card-header">
    </div>
    <div class="sctp-card-body">
      <div class="sctp-mar-b15 flex flex-sb pad10 sctp-lh-medium" style="border: 1px solid #e5e5e5;">
        <div>请选择支付方式</div>
        <div class="sctp-color-main">支付：¥{{ payAmount }}</div>
      </div>
      <el-radio-group v-model="payWay">
        <template v-for="item in payWayCandidate">
          <el-radio class="sctp-block" :disabled="item.disabled" :label="item.value" border style="height: 55px">
            <div class="sctp-flex-aic sctp-flex mg-l10">
              <template v-if="item.icon">
                <el-image
                  :src="item.icon"
                  style="height: 55px;width: 110px;"
                  :class="{'sctp-mar-r10':true,'mg-l4':item.value == 1}"
                  fit="cover"
                ></el-image>
              </template>
              {{ item.title }}
            </div>
            <!--<div class="sctp-color-main">支付：¥{{ payAmount }}</div>-->
          </el-radio>
        </template>
      </el-radio-group>
      <div class="sctp-pad-lr15 sctp-tc">
        <el-button :disabled="!payWay" size="small" type="primary" v-if="payAmount>0" @click="onPayClick">支付</el-button>
        <el-button  size="small" :disabled="!payWay" type="primary" v-if="payAmount<=0" @click="onPayClick">免费</el-button>
      </div>
    </div>
    <el-dialog
      append-to-body
      center
      width="500px"
      title="请输入支付密码"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      destroy-on-close
      :visible.sync="payPwdConfig.show">
      <div v-loading="payPwdConfig.checking" class="flex flex-center">
        <custom-password @success="payPwdConfig.inputEnd"></custom-password>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {wallet} from "../../apis";

export default {
  name: 'payComponent',
  components: {
    CustomPassword: () => import('@CMP/common/PayPwd'),
  },
  props: {
    payAmount: {
      type: Number,
      required: true
    },
    canUseWallet: {
      type: Boolean,
      default: true,
    },
    canUseReward: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      payWay: null,
      orders: null,
      wallet: null, // 余额
      reward: null, // 奖励金
      payWayCandidate: [
        {value: '1', disabled: false, icon: '/images/pay/zfbpay.png'},
        { value: '2', disabled: false, icon: '/images/pay/wxpay.png'},
      ],
      payPwdConfig: {
        show: false,
        checking: false,
        password: '',
        showDialog: () => {
          this.payPwdConfig.show = true;
        },
        closeDialog: () => {
          this.payPwdConfig.show = false;
        },
        inputEnd: (password) => {
          this.payPwdConfig.checking = true;
          wallet.checkPwd({
            userId: this.user.userId,
            password: password
          }).then(() => {
            this.payPwdConfig.closeDialog();
            this.paySuccess();
          }).finally(() => {
            this.payPwdConfig.checking = false;
          });
        },
      }
    }
  },
  watch: {
    payAmount: {
      handler(value) {
        let {payWayCandidate, wallet, payWay, canUseReward, reward} = this;
        if (value) {
          payWayCandidate.forEach(item => {
            if (item.value === '3') {
              // 钱包支付
              let disabled;
              if (canUseReward) {
                disabled = (reward ? reward + wallet : wallet) < value;
              } else {
                disabled = wallet < value;
              }
              item.disabled = disabled;
              if (item.disabled && payWay === '3') {
                this.payWay = null;
              }
            }
          });
          this.payWayCandidate = payWayCandidate;
        }
      }
    }
  },
  computed: {},
  methods: {
    onPayClick() {
      this.doPrivileged(this.doPay)
    },
    doPay() {
      let payWay = this.payWay;
      if (payWay === '3' && this.payAmount>0) {
        this.payPwdConfig.showDialog();
      } else {
        this.paySuccess();
      }
    },
    checkWalletPwd() {

    },
    paySuccess() {
      let payWay = this.payWay;
      this.$emit('pay', {
        payWay: payWay
      });
      this.payWay = null;
    },
    getWallet() {
      if (!this.user || !this.user.userId) return;
      wallet.getWallet({
        userId: this.user.userId
      }).then(res => {
        const {data = 0, reward} = res;
        let {canUseReward, payAmount} = this;
        this.wallet = data;
        this.reward = reward;
        let disabled;
        if (canUseReward) {
          disabled = (reward ? reward + data : data) < payAmount;
        } else {
          disabled = data < payAmount;
        }
        this.payWayCandidate.push({
          title: `余额  ${data.toMoney()} ${canUseReward ? '(奖励金' + reward + ')' : ''}`,
          value: '3',
          icon: '/images/pay/balance.png',
          disabled: disabled,
        })
      })
    }
  },
  beforeMount() {
    if (this.canUseWallet) {
      this.getWallet()
    }
  }
}
</script>

<style scoped lang="scss">
.sctp-block.el-radio {
  display: flex;
  margin-right: 0;
  margin-left: 0 !important;
  margin-bottom: 10px;
  padding-top: 0;
  align-items: center;
}
.mg-l4{
  margin-left: 4px;
}
.sctp-block.el-radio /deep/ .el-radio__label {
  flex: 1;
  display: inline-flex;
  justify-content: space-between;
}

.el-radio-button__inner, .el-radio-group {
  display: block;
}
</style>
